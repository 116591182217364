import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Connect QuickBooks Online">
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Connecting QuickBooks Online to Simple RM gives Simple RM permission to view the information in your QuickBooks Online account that it needs to see.`}</p>
    <p>{`These instructions assume you already have an existing Simple RM account that is not connected to QuickBooks Online. If you do not already have an account you can follow the account setup flow.`}</p>
    <p>{`Connecting QuickBooks Online to an existing account starts on this page. You must be signed into your Simple RM account to access it.`}</p>
    <h2 {...{
      "id": "sign-in"
    }}>{`Sign In`}</h2>
    <p>{`The first step of connecting is to now sign in to QuickBooks Online. Click the blue “Sign in with Intuit” button to do this. You will be taken to the QuickBooks Online / Intuit sign in page `}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
Intuit is the company that owns QuickBooks Online.
    </Message>
    <p>{`Once you sign in to QuickBooks Online you will be brought back to Simple RM.`}</p>
    <h2 {...{
      "id": "grant-permission"
    }}>{`Grant Permission`}</h2>
    <p>{`This time you will want to click the green “Connect to QuickBooks” button. When you do you will be taken back to a QuickBooks Online page.`}</p>
    <p>{`If you have multiple companies in your QuickBooks Online account you will be asked to choose one. Pick the company you want to connect to progress in the process.`}</p>
    <p>{`Once you select a company you will end up in the same place as people that only have one company in their QuickBooks Online account.`}</p>
    <p>{`This page explains what it means to connect Simple Review Management to your QuickBooks Online account.`}</p>
    <p>{`Once you have read through everything and you are comfortable to proceed click the “Connect” button.`}</p>
    <h2 {...{
      "id": "whats-next"
    }}>{`What’s Next`}</h2>
    <p>{`You will now be brought back to the spot where you left off in the Simple Review Management app.`}</p>
    <p>{`Simple RM now has the access it needs to function.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      